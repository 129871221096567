@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: linear-gradient(
    116.41deg,
    #eff4fb 16.09%,
    #f7f3ff 44.79%,
    #eff4fb 59.94%,
    #f7f4ff 95.81%
  ) !important;
  height: 100%;
}

html {
  background: linear-gradient(
    116.41deg,
    #eff4fb 16.09%,
    #f7f3ff 44.79%,
    #eff4fb 59.94%,
    #f7f4ff 95.81%
  ) !important;
  height: 100%;
}

code {
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}
#root {
  height: 100%;
}
.MuiTypography-body1 {
  letter-spacing: normal !important;
  font-family: "Montserrat", sans-serif !important;
}
.MuiTypography-root {
  letter-spacing: normal !important;
  font-family: "Montserrat", sans-serif !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 30px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.navbar-chatbot::-webkit-scrollbar {
  display: none !important;
}

.lab-trends-tab-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

.MuiCheckbox-icon {
  color: black !important;
}
.theme-blue-secondary-filter {
  filter: brightness(0) saturate(100%) invert(17%) sepia(64%) saturate(7325%)
    hue-rotate(232deg) brightness(97%) contrast(94%);
}

.swiper_container {
  width: 100%;
  position: relative;
}

.swiper-slide {
  opacity: 0.4;
  position: relative;
  z-index: 0 !important;
  display: flex !important;
  align-items: center !important;
  transition: all 0.7s ease-in-out;
  justify-content: center !important;
  padding: 10px;
}

.swiper-slide-next,
.swiper-slide-prev {
  opacity: 0.6;
  transition: all 0.7s ease-in-out;
}

.swiper-slide-active {
  opacity: 1;
  transition: all 0.7s ease-in-out;
}

.swiper-slide-active .main-card-body {
  border: 1px solid rgba(88, 125, 255, 0.5);
  box-shadow: 0px 0px 14px 0px #abbeff;
  transition: all 0.4s ease-in-out;
}
.swiper-slide .cards {
  width: 37rem;
  height: 42rem;
  object-fit: cover;
  border-radius: 2rem;
}

.swiper-slide-shadow-left {
  background-image: none !important;
}

.swiper-slide-shadow-right {
  background-image: none !important;
}

.slider-controler {
  margin-top: 30px;
}

.swiper-pagination-bullet {
  margin: 0 !important;
  border-radius: 0 !important;
}

.cus-textarea,
.cus-textarea:focus {
  resize: none;
  border: none;
  outline: none;
}

.cus-textFocus:focus {
  border: 2px solid black;
}

/* tooltip code  */
.tooltipRoot .MuiTooltip-Popper {
  background: #ffffff !important;
  border-radius: 6px !important;
  padding: 10px !important;
  color: #1b1b1b !important;
}
/* Text to Speech button */
.speech-wrapper {
  display: flex;
  align-items: center;
}
.text-to-speech button {
  border-radius: 100px;
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.text-to-speech .play-button img {
  padding-left: 3px;
  width: 32px;
  height: 32px;
}

/* Patient Detail css handle here */
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1366px) and (max-height: 1366px) {
  .healthsummary_wrapper,
  .vital-lab-med-wrapper {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 850px) {
  .navbar-select-dropdown {
    width: auto !important;
    min-width: auto !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .time-box .time-display {
    min-width: auto !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 768px) and (max-height: 768px) and (orientation: landscape) {
  .settings-main-wrapper .swiper_container {
    height: 69vh;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (min-height: 1024px) and (max-height: 768px) {
  .settings-main-wrapper .swiper_container {
    height: 69vh;
  }
}
@media only screen and (min-width: 768px) and (max-width: 768px) and (min-height: 1024px) and (max-height: 1024px) {
  .settings-main-wrapper .swiper_container {
    height: 74vh;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (min-height: 1024px) and (max-height: 1024px) and (orientation: landscape) {
  .settings-main-wrapper .swiper_container {
    height: 73vh;
  }
}
@media only screen and (min-width: 1180px) and (max-width: 1180px) and (min-height: 820px) and (max-height: 820px) and (orientation: landscape) {
  .settings-main-wrapper .swiper_container {
    height: 71vh;
  }
}
@media only screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) and (orientation: portrait) {
  .settings-main-wrapper .swiper_container {
    height: 71vh;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1366px) and (max-height: 1366px) {
  .settings-main-wrapper .swiper_container {
    height: 64vh;
  }
  .main-card-body {
    width: 450px !important;
    height: 665px !important;
    padding: 20px !important;
  }
}
@media only screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
  .settings-main-wrapper .swiper_container {
    height: 74vh;
  }
}
